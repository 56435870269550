import { CSSProperties, useEffect } from 'react';
import anime from 'animejs/lib/anime.es';

const FlitSvgAnimation = () => {
  useEffect(() => {
    anime({
      targets: '.st0',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 3500,
      delay: function(el, i) { return i * 250; },
      direction: 'normal',
      translateX: [-150,0],
      loop: true
    });
  }, []);
 
  //  enable-background:new 0 0 1500 512;
  return (
   <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 1500 512"  >
<style type="text/css">
	.st0{}
    </style>
    <path className="st0" style={svgIm} d="M226.5,111.6l-5.2,98.5c-3.1-0.6-9.8-1.5-20-2.7c-6.1-0.3-13.4-0.5-21.7-0.6l-2.2,50.4
		c8.8,0.2,22.3-0.6,40.6-2.3c-0.9,4.7-2.2,12.4-4,23.2c-0.9,6.4-2,14-3.1,23c-2.4-0.6-7.9-1.6-16.5-2.8c-6.4-0.6-12.7-0.8-18.9-0.6
		l-1,54.5H103l-4-122.8c-0.7-28.2-2.6-67.4-5.7-117.8H226.5z"/>
	<path className="st0" style={svgIm} d="M368.3,304.8c-0.9,4.2-2.4,12.1-4.7,23.6c-1.2,6.8-2.2,14.7-3.1,23.8H245.1c-0.4-24.5-1.5-64.9-3.2-121.2
		c-1.1-28.8-3.1-68.6-6-119.3h88.5l-7.3,199.5c8.2,0,20.6-1.1,37.1-3.3C357.7,307.5,362.4,306.4,368.3,304.8z"/>
	<path className="st0" style={svgIm} d="M462.8,111.6l-5,128c-0.9,27.6-2,65.1-3.3,112.6H383l-8.7-240.6H462.8z"/>
	<path className="st0" style={svgIm} d="M629,111.6l-3.7,98.5c-3.3-0.6-8.6-1.4-15.9-2.3c-4.5-0.3-11.8-0.6-21.9-0.9c-0.5,6.2-1.4,21-2.7,44.6
		c-0.9,22.9-2.5,56.4-4.6,100.7h-61.7c-0.7-21.1-2-54.2-3.8-99.5c-0.9-15.7-1.7-31-2.7-45.9l-20.2,0.9l-18.2,2.3l-3.2-98.5H629z"/>
	<path className="st0" style={svgIm} d="M881.6,111.6c-1.4,25.7-3.4,67.5-5.7,125.5c-0.7,27.6-1.9,65.9-3.6,115.1h-65.1l-1.4-52.6
		c-1.5,0.6-4.6,2.1-9.3,4.5c-5.5,2.9-9.4,5.3-11.7,7l-6.6-19.3c-1.4-4.2-3.6-10.1-6.8-17.7l-1.3,78h-71.8l-3.7-115.1
		c-0.8-30-2.7-71.8-5.7-125.5h89.5l-6,148.2c6.2,0.7,12.2,0.9,17.9,0.6c3.7-0.1,8.6-0.5,14.8-1.3l-6.5-147.5H881.6z"/>
	<path className="st0" style={svgIm} d="M980.6,111.6l-5,128c-0.9,27.6-2,65.1-3.3,112.6h-71.6l-8.7-240.6H980.6z"/>
	<path className="st0" style={svgIm} d="M1161.6,249.3c-0.9,8.9-2.1,25.6-3.8,50.3c-0.9,14.7-1.7,32.2-2.4,52.3c-11.4,4.1-28,6.1-49.9,6.1
		c-32.5,0-58.9-9-79.2-26.9c-24-21.4-36-53.2-36-95.5c0-40.5,10.5-72.1,31.5-94.9c19.7-21.4,45.7-32.1,77.9-32.1
		c12.2,0,25.2,1.2,39.2,3.4l7,98.8c-5.6-2.6-13.6-3.9-24-3.9c-13.2,0-25.1,5-35.7,14.9c-11.9,11.2-17.9,25.4-17.9,42.6
		c0,16.3,5.1,28.9,15.2,37.7c8.5,7.5,18.3,11.1,29.5,10.6l-2-31.5c-0.5-6.5-1.7-17.1-3.4-31.9H1161.6z"/>
	<path className="st0" style={svgIm} d="M1361,111.6c-1.4,25.7-3.4,67.5-5.7,125.5c-0.7,27.6-1.9,65.9-3.6,115.1h-65.1l-1.4-52.6
		c-1.5,0.6-4.6,2.1-9.3,4.5c-5.5,2.9-9.4,5.3-11.7,7l-6.6-19.3c-1.4-4.2-3.6-10.1-6.8-17.7l-1.3,78h-71.8l-3.7-115.1
		c-0.8-30-2.7-71.8-5.7-125.5h89.5l-6,148.2c6.2,0.7,12.2,0.9,17.9,0.6c3.7-0.1,8.6-0.5,14.8-1.3l-6.5-147.5H1361z"/>
</svg>
  );
};

export default FlitSvgAnimation;

const svgIm: CSSProperties = {
  fill: "none",
  stroke: '#fff',
  strokeWidth:10
}


// const sdd = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1314.29 402.65"><title>Untitled-1</title>

//   transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M324.88,359.58H291.25V44.36h33.63Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M378.87,78.63a24.46,24.46,0,0,1,5-15.6q5-6.36,14.82-6.36T413.59,63a24.19,24.19,0,0,1,5.09,15.6q0,9.24-5.09,15.39t-14.91,6.16q-9.82,0-14.82-6.16T378.87,78.63Zm36.36,281H381.6V137.53h33.63Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M514.66,83.76v53.77h36.72v29.35H514.66V304.76q0,13.36,4.91,20t16.72,6.68q5.82,0,16-2.46v30.6a87.64,87.64,0,0,1-25.81,4.08q-22.54,0-34-15.39T481,304.58V166.88H445.22V137.53H481V83.76Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M891.5,359.58H856.41V221.47H723V359.58h-34.9V60.78H723V189.25H856.41V60.78H891.5Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M948.39,78.63a24.46,24.46,0,0,1,5-15.6q5-6.36,14.82-6.36T983.11,63a24.19,24.19,0,0,1,5.09,15.6q0,9.24-5.09,15.39t-14.91,6.16q-9.82,0-14.82-6.16T948.39,78.63Zm36.36,281H951.12V137.53h33.63Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M1030.56,246.71q0-51.92,21.27-82.6t56.35-30.68q36,0,56.17,28.73l1.64-24.63h30.72V354.25q0,43.1-22.63,67.93T1113.27,447a91.53,91.53,0,0,1-41.63-10.26q-20.36-10.26-31.08-28.12L1058,385.85q21.63,30.17,52.9,30.17,24.54,0,38.27-15.6t13.72-43.92V337.42q-20.18,26.27-55.08,26.27-34.54,0-55.9-31.4T1030.56,246.71Zm33.81,4.31q0,37.56,13.63,59t38.17,21.45q31.81,0,46.72-32.63V197.46q-15.45-31.81-46.35-31.81-24.54,0-38.36,21.55T1064.37,251Z" transform="translate(-87.83 -44.36)" style="fill:blue" /><path d="M1281.05,164.42q22.36-31,58.17-31,62.35,0,62.9,79.42V359.58h-33.63V212.64q-.18-24-9.73-35.5T1329,165.65a44.75,44.75,0,0,0-28.72,9.85,65.52,65.52,0,0,0-19.27,25.86V359.58h-33.63V44.36h33.63Z" transform="translate(-87.83 -44.36)" style="fill:blue" /></svg>
