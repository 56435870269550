import styled, { CSSProperties } from 'styled-components';
import homePost from '../asset/web3.png';
import { Call } from '@mui/icons-material';
import { Box, Container, Grid, Typography, useMediaQuery, Button, Link } from '@mui/material';
import anime from 'animejs/lib/anime.es';
import flitSvg from '../asset/flithigh4.svg';
import { useEffect } from 'react';
import FlitSvgAnimation from './flitimag';


function StartPage() {
  const isDesktop = useMediaQuery('(min-width: 900px)');
    useEffect(() => {
    anime({
      targets: '.st0',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 3500,
      delay: function(el, i) { return i * 250; },
      direction: 'normal',
      loop: false
    });
  }, []);

  const onClickPhoneNumber = () => {
   
    window.location.href = `tel:`;
  };

 


  return (
    <div style={bg}>
        <StyledContainer > 
      <Grid container spacing={2} minHeight="80vh">
        {BuildImageLayout(isDesktop)}
        {BuildTextLayout()}
      </Grid>
    </StyledContainer>
    </div>
  
  );
}

export default StartPage;

const bg: CSSProperties = {
  backgroundColor: '#1D555d',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: '#ffffff',
};



const StyledContainer = styled(Container)`
  /* Add container-specific styles if needed */
`;

const BuildImageLayout = (isDesktop :boolean) => (
  <Grid item xs={12} md={6}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
      }}
    >
      <img
        src={homePost}
        alt="Web3 Illustration" // Provide a descriptive alt text
        style={{ width: isDesktop ? 'calc(50vw - 50px)' : '80vw' }}
      />
    </Box>
  </Grid>
);

const BuildTextLayout = () => (
  <Grid item xs={12} md={6}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80%' }}>
      <StyledTextContent>
      
        <FlitSvgAnimation />
        <Typography variant="h2" component="h2" sx={{ fontSize: '2.5rem', marginBottom: '1rem', fontWeight:'bold' }}>
          Design. Develop. Disrupt.
        </Typography>
        <Typography variant="h4" style={{ marginBottom: '1rem', fontSize: '2rem' }}>
          We craft innovative software that changes the game.
        </Typography>
        <Link variant='h2'  color={'#fff'} style={{fontSize :'2.5rem', textDecoration :'none' }} >
           
        </Link>
        <Button
  variant="outlined"
  sx={{ backgroundColor: '#fff', color: '#00f', width: 150, marginBottom: '1rem' }}
  onClick={() => window.location.href = 'tel:8248234154'}
>
  +91-8248234154
</Button>
        <Box sx={{ marginBottom: '2rem' }} /> {/* Spacer for visual clarity */}
      </StyledTextContent>
    </Box>
  </Grid>
);

const StyledTextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;