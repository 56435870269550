import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import About from './components/About'; // Import your About component
import Landing from './landing';
import { AppwriteClient } from './core/appwrite';


interface AppState {
  anonymousUserCreated: boolean;
}

class App extends Component<{}, AppState> {
  state: { anonymousUserCreated: false; };
  constructor(props: {}) {
    super(props);
    this.state = {
      anonymousUserCreated: false,
    };
  }

  componentDidMount(): void {
    var session = localStorage.getItem('session')
    if (session == null) {

      this.createAnonymousUser();
    }
  }

  createAnonymousUser = async (): Promise<void> => {
    var appwriteClient  = new AppwriteClient();
    const response = await appwriteClient.getAccount()
    this.setState({ anonymousUserCreated: true });
  }

  render(): React.ReactNode {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<Greeting />} />
        </Routes>
      </BrowserRouter>
    );
  }
}


export default App;




interface GreetingProps {
  name: string;
}

function Greeting() {
  return (
    <h1>Hello, </h1>
  );
}

Greeting.defaultProps = {
  name: 'World',
};

