import React from 'react';
import StartPage from './StartPage';
import FocusOnSale from './FocusOnSale';
import WhatWeDevelop from './WhatWeDevelop'
import BookSchedule from './Schedule';
import WhySoftware from './whySoftware';
import Footer from '../Footer';
import Header from '../Header';

function Landing() {
  return (
    <div>
      <Header />
      <StartPage />
      <WhatWeDevelop />
      <FocusOnSale />
      <WhySoftware />  
      <BookSchedule />
      <Footer />
    </div>
   
  );
}

export default Landing;
