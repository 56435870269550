import React from 'react'
import logo from './asset/icons/tlogo.png'

export default function Header() {
   const isMobile = window.innerWidth <= 600;
  return (
   <div  >
     <div style={{backgroundColor:"#fff", position:'fixed', padding :5, height:isMobile? 55 : 110, width:'100vw',   }} >
    <img src={logo} width={isMobile?180 : 300} height={isMobile? 55 : 90} alt="" />
    </div>
      <div style={{backgroundColor:"#fff",  padding :5, height:isMobile? 55 : 110, width:'100vw'}} >
    <img src={logo} width={isMobile?180 : 300} height={isMobile? 55 : 100} alt="" />
    </div>
   
    </div>
  )
}
