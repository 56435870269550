import { AnalyticsOutlined, Public } from '@mui/icons-material';
import { queries } from '@testing-library/react';
import { Account, Client, Databases, Functions, Models } from 'appwrite'; // Assuming you have the Appwrite SDK installed
import {  v4 } from 'uuid';

export class AppwriteClient {
 private readonly client: Client;

 readonly endPoint = 'https://cloud.appwrite.io/v1';
 readonly project = '6684202f0001de688b81';
 readonly SessionDataCollectionId = '668440d40008b07cc8f6';
 readonly databaseId = '668440bd002a6ed6fd66';

constructor() {
  this.client = new Client();
  this.client.setEndpoint(this.endPoint);
  this.client.setProject(this.project);

  }

  // Access Appwrite services (e.g., Account, Databases, Functions) through methods:
  public async getAccount()  {
   try {
    const account = new Account(this.client);
    
    await account.get();
    console.log("user exisng ");

    

   } catch (error) {
    // User not exising Create Anonymous Session
    await this.createAnonymousSession()
    console.log(error);
   }
  }

  public getDatabases(): Databases {
    return new Databases(this.client);
  }

  public getFunctions(): Functions {
    return new Functions(this.client);
  }

 
 public async createAnonymousSession() {
  try {
    const account = new Account(this.client);
    const db = new Databases(this.client);
    var session = localStorage.getItem('session')
    if (session == null) {
       const response: Models.Session = await account.createAnonymousSession();
   console.log(`ID :${response.$id.length}`);
   console.log(`createdAt :${response.$createdAt.length}`);
    console.log(response.$id);
  await localStorage.setItem('session', JSON.stringify(`${response.$id}`));
  const { providerAccessToken, providerAccessTokenExpiry, providerRefreshToken, secret,factors,mfaUpdatedAt, $id, $createdAt, $updatedAt, ...sessionData } = response;
    const docID = v4();
  const per = [];
  
  await db.createDocument(this.databaseId, this.SessionDataCollectionId, $id, {
    id: $id,
    createdAt: $createdAt,
    updatedAt : $updatedAt,
    ...sessionData
   });
    
    }
  
  } catch (error) {
   console.log(error);
  }
 

 }
  

}



