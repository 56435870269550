import { Box, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust breakpoint as needed

 return (
   <div style={{backgroundColor : '#686D76', color :'#fff', paddingTop :20, paddingBottom:30}} >
   <div style={{ display: 'flex', flexDirection :isMobile ? 'column' : 'row', justifyContent: 'space-between',  }}>
    
      <Box sx={{paddingLeft :3, paddingBottom:5, maxWidth:350}} >
       <Typography variant="h5" style={{fontWeight :'bold'}}  >
            Get in touch:
      </Typography>
      <Typography variant="h6"  >
       1/52, Elampillai-Kakapalayam MainRoad,
       Perumagoundampatti, Elampillai (pt) - 637502 
      </Typography>
      <Typography variant="h6"  >
       Call  8248234154
       </Typography>
        </Box>


     <Box  sx={{paddingLeft :3, paddingBottom:5, paddingRight:10}} >
      <Typography variant="h5"style={{fontWeight :'bold'}} > Thanks & Credits To </Typography>
      <ul>
       <li><Typography variant="h6"  >Icon8</Typography></li>
       <li><Typography variant="h6"  >Freepik</Typography></li>
       <li><Typography variant="h6"  >Google</Typography></li>
       <li><Typography variant="h6"  >React Community</Typography></li>

      </ul>
      
      
 
        </Box>

     
    </div>
   </div>
    
  );
};

export default Footer;
