
import React, { useState, useEffect,CSSProperties } from 'react';
import { Card, CardHeader, CardMedia, Container, Typography, Grid } from "@mui/material";
import homePost1 from '../asset/r/website.jpg';
import homePost from '../asset/r/mobile1.jpg';
import searchimg from '../asset/r/Search.jpg';
import postimg from '../asset/r/digMarketing.jpg';
import anime from 'animejs/lib/anime.es';
import { css } from 'styled-components';

function WhatWeDevelop() {

   useEffect(() => {
    anime({
  targets: '.demo',
  translateX: function(el:any) {
    return el.getAttribute('data-x');
  },
  translateY: function(el :any, i:any) {
    return 50 + (-50 * i);
  },
  scale: function(el :any, i:any, l:any) {
    return (l - i) + .25;
  },
  rotate: function() { return anime.random(-360, 360); },
  borderRadius: function() { return ['50%', anime.random(10, 35) + '%']; },
  duration: function() { return anime.random(1200, 1800); },
  delay: function() { return anime.random(0, 400); },
  direction: 'alternate',
  loop: true
});
  }, []);
    const isMobile = window.innerWidth <= 600;
  return (
    <div style={bg}>
      <div style={{ padding: isMobile ?10: 30 }} />
      <Typography variant="h3" style={{ color: "#373A40", fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
        Our Service
      </Typography>
      <Typography variant="h5" style={{ color: "#373A40", fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
        Solutions Made Simple 
      </Typography>
      <div style={{ padding: isMobile ?10: 30 }} />
      <div className='demo' style={{ display: isMobile ? 'block' : 'flex', margin: 'auto', justifyContent: 'space-around', alignItems: 'center' }}>
       
          {ProductCard(homePost1, 'Website Development')}
          {ProductCard(homePost, 'Mobile App Development')}
          {ProductCard(searchimg, 'Search engine optimization')}
          {ProductCard(postimg, 'Digital marketing')}
        
      </div>
        <div style={{ padding: isMobile ?10: 30 }} />
    </div>
  );
}


function ProductCard(path :string,title :string ) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardStyle :CSSProperties = {
    display: isMobile ? 'grid' : 'block',
    gridTemplateColumns: isMobile ? '30vw 40vw' : undefined,
    height: isMobile ? window.innerWidth / 3.5 : undefined,
    backgroundColor: '#dddddd',
    margin: '1rem',
    borderRadius: 8,
    padding: '1px',
    color: '#333',
  };

  

  const imageStyle :CSSProperties = {
    width: isMobile ? '100%' : window.innerWidth / 5,
    height: isMobile ? '100%' : window.innerWidth / 6,
    objectFit: 'contain',
  };

  const titleStyle :CSSProperties = {
    textAlign: 'center',
    fontSize: isMobile ? 18 : 20,
  };

  return (
    <div style={cardStyle}>
      <h3 style={titleStyle}>{title}</h3>
      <img src={path} alt={title} style={imageStyle} />
      
    </div>
  );
}
export default WhatWeDevelop;


const bg: CSSProperties = {
  backgroundColor: '#fff',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 10,
};


const slideUpFromBottom = css`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

