import React, { CSSProperties, useState } from 'react';
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import shd from "../asset/chat.png"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

function BookSchedule() {
 return <div style={bg}>
   <Container >
     <Grid container spacing={2} minHeight="80vh">
       {BuildImageLayout(true)}
       <MyForm ></MyForm>
     </Grid>
  
  </Container>
 </div>
}

export default BookSchedule;

const bg: CSSProperties = {
  backgroundColor: '#D6EFD8',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '80vh', 
  padding:10 
};


const BuildImageLayout = (isDesktop: boolean) => {
   const phoneNumber = '8248234154';
  const telHref = `tel:${phoneNumber}`;

  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%',
        }}
      >
        <img
          src={shd}
          alt="Web3 Illustration" // Provide a descriptive alt text
          style={{ width: isDesktop ? 'calc(50vw - 50px)' : '80vw' }}
        />
        
      </Box>

    </Grid>
  )
};


interface FormData {
  name: string;
  mobileNumber: string;
  
}

const MyForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    mobileNumber: '',
   
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

 

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Form validation (optional, add validation logic here)
    if (formData.mobileNumber === '' ) {
      alert('Please fill out all required fields.');
      return;
    }

    console.log('Submitted form data:', formData);

    // Handle form submission (e.g., send data to server)
  };

  return (
    <form style={{ margin: 'auto', backgroundColor: '#fff', padding: 30 }} onSubmit={handleSubmit}>
      <Typography >
        Request a Website & App Development
      </Typography>
      <div  style={{padding:10}}  /> 
      <TextField
        label="Name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
       <div  style={{padding:10}}  /> 
      <TextField
        label="Mobile Number"
        name="mobileNumber"
        value={formData.mobileNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <div  style={{padding:10}}  /> 
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
  );
};