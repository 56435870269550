import React, { CSSProperties } from 'react';
import { Card, CardMedia, CardHeader, CardContent, Container, Typography, Grid } from "@mui/material";
import saleImage from '../asset/sale.png'; // Import images with descriptive names
import growImage from '../asset/grow.png';
import storyImage from '../asset/story.png';
import lightbulbImage from '../asset/lightbulb.png';


function WhySoftware() {
   const isMobile = window.innerWidth <= 600;
  return <div style={bg} >
    <div style={{ padding: isMobile ? 10 : 30 }} />
    <Typography variant="h3" style={{ color: "#373A40", fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
        awesome
    </Typography>
     <Typography variant="h5" style={{ color: "#373A40", fontWeight: 'bold', marginBottom: '1rem', textAlign: 'center' }}>
        Solutions Made Simple 
    </Typography>
    {Panel()}
     <div style={{ padding: isMobile ? 10 : 30 }} />
    
      <div style={{ padding: isMobile ?10: 30 }} />
 </div>
}

export default WhySoftware;


const alwaysOnSalesText = "Let your website become your tireless salesperson. Make it easy for customers to buy with a seamless online experience.";

const brandStoryText = "Showcase your unique identity. Connect with your audience on a deeper level. Share your values and vision to build trust.";

const automateText = "Streamline workflows with automated solutions. Free yourself from repetitive tasks. Automate processes to save time and resources.";

const growSmarterText = "Grow smarter, not just harder. Gain valuable insights, make informed decisions, and achieve sustainable success with the right tools and data analysis.";


function MobileFrame(image: string, title: string, subtitle: string) {
  const isMobile = window.innerWidth <= 600;
  return (
    <div >
      <img style={{ width: '20%', objectFit: 'contain' }} src={image} />
      <div >
        <Typography variant="body1" fontWeight='bolder' >{title}</Typography>
        <Typography variant="body2" fontWeight='bold' >{subtitle}</Typography>
      </div>
      
    </div>
  );
}



function Panel() {
   const isMobile = window.innerWidth <= 600;
  if (isMobile) {
      return (
        <div style={{ padding :13, color :'black', fontWeight:'bold'}} >
            {MobileFrame(saleImage, 'On Sales', alwaysOnSalesText)}
            {MobileFrame(storyImage, 'Brand Story', brandStoryText)}
            {MobileFrame(lightbulbImage, 'Automate', automateText)}
            {MobileFrame(growImage, 'Grow Smarter', growSmarterText)}
        </div>
  );
  } else {
    return (
      <Container >
       <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12} md={3}>
          {DeskFrame(saleImage, 'On Sales', alwaysOnSalesText)}
        </Grid>
        <Grid item xs={12} md={3}>
          {DeskFrame(storyImage, 'Brand Story', brandStoryText)}
        </Grid>
        <Grid item xs={12} md={3}>
          {DeskFrame(lightbulbImage, 'Automate', automateText)}
        </Grid>
        <Grid item xs={12} md={3}>
          {DeskFrame(growImage, 'Grow Smarter', growSmarterText)}
        </Grid>
      </Grid>
    </Container>
    )
  }

}



function DeskFrame(image: string, title: string, subtitle: string) {
  const isMobile = window.innerWidth <= 600;
  return (
    <Card sx={{ margin: '1rem', backgroundColor: '#686D76', color: '#fff', padding: 2 }}>
      <CardHeader title={title} sx={{ textAlign: 'center' }} />
      <CardMedia component="img" image={image}
        width={isMobile ? '50%' : window.innerWidth / 7}
        height={isMobile ? '50%' : window.innerWidth / 7}
        sx={{ objectFit: 'contain'}} />
      <CardContent>
        <Typography variant="body2">{subtitle}</Typography>
      </CardContent>
    </Card>
  );
}

// function DeskFrame(path: string, title: string, subtitle: String) {
//    
//  return (<Card sx={{margin:'1rem', backgroundColor:'#686D76', color:'#fff',padding:2, width :isMobile ? '100%' : window.innerWidth / 4 }} >
   
//    <CardHeader title={title} sx={{ textAlign: 'center' }} />

//    <div style={{display: isMobile ? 'grid' : 'block',
//     gridTemplateColumns: isMobile ? '30vw 40vw' : undefined,}} >
//    </div>
//       <CardMedia
//        component="img"
//        width ={isMobile ? '100%' : window.innerWidth / 5} 
//        height={isMobile ? 'auto' : window.innerWidth / 7}   
//        image={path}
//        sx={{ objectFit: 'cover' }}
//      />
//       <Typography variant='body2' style={{ color: "#fff", fontWeight: 'bold', marginBottom: '1rem', textAlign: 'left' }}>
//         {subtitle}
//     </Typography>
  
//   </Card>);
// }



const bg: CSSProperties = {
  backgroundColor: '#EEEEEE',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  padding: 10,
};