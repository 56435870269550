import React from 'react';
import { Call } from '@mui/icons-material';
import { Box, Container, Grid, Typography, useMediaQuery, Button } from '@mui/material';
import imgref from '../asset/r/10355715_4447725-removebg-preview.png';

function FocusOnSale() {
   const isMobile = window.innerWidth <= 600;
  const isDesktop = useMediaQuery('(min-width: 900px)');// Adjust breakpoint as needed

  const onClickPhoneNumber = () => {
    // Handle phone number click logic here
  };

 

  return (
    <div style={{ display:'flex', flexDirection:isMobile ? 'column' : 'row',  backgroundColor: '#222', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', color: '#ffffff' }}>
    
             <img src={imgref} alt="" style={{ width: isDesktop ? '50%' : '100%' }} /> 
       <div style={{ margin: 'auto', color: '#fff', padding:30 }}>
        <Typography  sx={{fontSize :isMobile ?30 :50 , fontWeight :'bolder', textAlign :'left'}}  >Custom development</Typography>
        <div style={{  padding:5 }}></div>
        <Typography  sx={{fontSize :isMobile ?22 :30,fontWeight :'bolder', textAlign :'left'}} >We Build What You Need,</Typography>
        <div style={{  padding:3 }}></div>
          <Typography  sx={{fontSize :isMobile ?18 :25,fontWeight :'bolder', textAlign :'left'}}>Your Vision, Our Expertise</Typography>
        </div>
    
   
      
    </div>
  );
}

export default FocusOnSale;
